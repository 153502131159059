<template>
  <div class="list-clients">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <navigator :items="navigate_items"></navigator>
        </div>
        <div class="col-12">
          <div class="card card-shadow">
            <div class="card-body">
              <div class="table-header d-flex justify-content-between">
                <button class="btn btn-primary btn-add" v-on:click="newItem()">
                  <i class="fa fa-plus" aria-hidden="true"></i> Adicionar
                </button>
                <input
                  type="text"
                  placeholder="Pesquisar"
                  v-model="filterTerm"
                  class="form-control"
                  v-on:keyup="filterTable()"
                />
              </div>
              <b-table
                hover
                no-local-sorting
                :fields="fields"
                :items="data"
                :per-page="0"
                :current-page="currentPage"
                @row-clicked="selectItemTable"
                @sort-changed="sortingChanged"
              >
                <template v-slot:cell(phones)="data">{{
                  data.value.length > 0 ? data.value[0].number : "-"
                }}</template>
                <template v-slot:cell(created_at)="data">{{
                  formatDate(data.value)
                }}</template>
                <template v-slot:cell(id)="data">
                  <div class="d-flex">
                    <button
                      class="btn btn-success float-right btn-update-table"
                      v-on:click="updateDonation(data)"
                      :disabled="!adminLogged()"
                    >
                      <i class="fa fa-share" aria-hidden="true"></i>
                    </button>
                    <button
                      class="btn btn-primary float-right btn-cancel-table"
                      v-on:click="cancelDonation(data)"
                      :disabled="!adminLogged()"
                    >
                      <i class="fa fa-ban" aria-hidden="true"></i>
                    </button>
                    <button
                      class="btn btn-primary float-right btn-destroy-table"
                      v-on:click="destroy(data)"
                      :disabled="!adminLogged()"
                    >
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                </template>
              </b-table>
              <div class="no-data" v-if="data.length == 0">
                <span>Sem dados</span>
              </div>
              <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helper from "@/imports/Helpers";

export default {
  name: "list-clients",
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      rows: 0,
      data: [],
      filter: {
        name: "",
      },
      includes: ["phones"],
      sortBy: "",
      filterTerm: "",
      fields: [
        { key: "name", label: "Nome", class: "name-cell", sortable: true },
        { key: "phones", label: "1º Telefone", class: "phone-cell" },
        { key: "cpf", label: "CPF", class: "cpf-cell" },
        {
          key: "consumer_unit",
          label: "Unidade Consumidora",
          class: "consumer-cell",
        },
        {
          key: "created_at",
          label: "Data de cadastro",
          class: "date-cell",
          sortable: true,
        },
        { key: "id", label: "", class: "remove-cell" },
      ],
      navigate_items: [
        {
          text: "Clientes",
          active: true,
        },
      ],
    };
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.providerData();
      },
    },
  },
  mounted() {
    this.providerData();
  },
  methods: {
    providerData() {
      helper
        .getPaginateQuery(
          "clients",
          helper.getUrlBuildQuery(
            this.perPage,
            this.currentPage,
            this.filter,
            this.includes,
            this.sortBy
          )
        )
        .then((response) => {
          this.rows = response.data.total;
          this.data = response.data.data;
        });
    },
    selectItemTable: function (record, index) {
      this.$router.push({
        name: "dashboard.client",
        params: { id: record.id },
      });
    },
    adminLogged() {
      return helper.isAdminLogged();
    },
    cancelDonation: function (record, index) {
      this.$router.push({
        name: "dashboard.cancel-donation",
        params: { id: record.item.id, type: "create" },
      });
    },
    updateDonation: function (record, index) {
      this.$router.push({
        name: "dashboard.update-donation",
        params: { id: record.item.id, type: "create" },
      });
    },
    newItem: function () {
      this.$router.push({ name: "dashboard.client" });
    },
    sortingChanged: function (sortData) {
      this.sortBy = sortData.sortDesc ? `-${sortData.sortBy}` : sortData.sortBy;
      this.currentPage = 1;
      this.providerData();
    },
    filterTable: function () {
      this.filter.filter = this.filterTerm;
      this.currentPage = 1;
      this.providerData();
    },
    destroy: function (dataToDelete) {
      helper.destroyDataObject("clients", dataToDelete.item).then(() => {
        this.data.splice(dataToDelete.index, 1);
      });
    },
    formatDate: function (date) {
      return helper.formatDateTime(date);
    },
    formatMoney: function (value) {
      return helper.formatMoney(value);
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/general.scss";
.list-clients {
  .name-cell {
    width: 40%;
  }
  .cpf-cell {
    width: 20%;
  }
  .phone-cell {
    width: 20%;
  }
  .date-cell {
    width: 20%;
  }
}
</style>